<template>
  <v-dialog
    v-model="isOpened"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-container>
        <v-form @submit.prevent="onSubmit" v-model="valid" autocomplete="off">
          <v-card-text>
            <v-select
              label="Регион"
              required
              v-model="modalUser.region_id"
              :items="regions"
              item-value="id"
              item-text="name"
              @input="resetErrors"
              :error-messages="errors.region_id"
            />

            <v-text-field
              label="Фио"
              required
              v-model="modalUser.name"
              @input="resetErrors"
              :error-messages="errors.name"
            />

            <v-text-field
              label="Email"
              required
              v-model="modalUser.email"
              @input="resetErrors"
              :error-messages="errors.email"
            />

            <div>
              <v-text-field
                label="Телефон"
                required
                v-model="modalUser.phone"
              />
            </div>

            <div>
              <v-text-field
                autocomplete="new-password"
                hint="Хороший пароль"
                :rules="[rules.password]"
                @input="_=>modalUser.password=_"
                label="Пароль"
                type="password"

              />
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn
              color="blue darken-1"
              text
              @click="onCancelClick"
            >
              Отмена
            </v-btn>

            <v-btn
              color="blue darken-1"
              outlined
              type="submit"
              :disabled="!valid"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { ListsService, UsersService } from '@/common/api.service'

export default {
  props: {
    user: {
      default: null
    }
  },

  data() {
    return {
      types: [],
      regions: [],
      modalUser: {
        region_id: null,
        name: null,
        email: null,
        phone: null,
        district_ids: [],
        type_id: null,
        password: null
      },
      isOpened: false,

      valid: true,
      rules: {
        required: value => !!value || "Поле обязательно для заполнения.",
        password: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
          return (
            pattern.test(value) ||
            "Мин. 8 символов, как минимум одна заглавная буква, число и спецсимвол (!@#$%^&*)."
          );
        }
      },

      errors: {},
    }
  },
  methods: {
    onSubmit() {
      if( this.user.id ) {
        UsersService.update(this.user.id, this.modalUser).then(() => this.$emit('close', true))
      } else {
        UsersService.add(this.modalUser, 'filial_manager')
          .then(() => this.$emit('close', true))
          .catch(error => {
            if (error.response && 422 === error.response.status) {
              this.errors = error.response.data.errors
            }
          })
      }
    },

    onCancelClick() {
      this.$emit('close', false)
    },

    resetErrors () {
      this.errors = {}
    }
  },
  watch: {
    user (value) {
      if (value) {
        this.isOpened = true
        for (const [key] of Object.entries(this.modalUser)) {
          this.modalUser[key] = this.user[key]
        }
      } else {
        this.isOpened = false
      }
    }
  },
  beforeMount() {
    ListsService.get(['types', 'regions']).then(({data}) => {
      this.types = data.types
      this.regions = data.regions
    })
  }
}
</script>
